<template>
  <div class="login">
    <div class="login__header">
      <div class="login__header--title">
        <img src="@/assets/logo.png" alt="logo" width="180px" >
        <strong>API管理系統</strong>
      </div>
    </div>

    <div class="login__func">
      <div class="login__func--infoCard">
        <div class="flex align-center justify-center">
          <label style="color:black;font-weight:700">帳號：</label>
          <input type="text" v-model.trim="userInfo.username" placeholder="請輸入帳號">
        </div>
        <div class="flex align-center justify-center">
          <label style="color:black;font-weight:700">密碼：</label>
          <input type="password" v-model.trim="userInfo.password" placeholder="請輸入密碼">
        </div>
        <button @click="login()">登入</button>
      </div>
    </div>

    <div class="footer">
      <p>Copyright © 2022 minds Corporation. V1007</p>
    </div>
  </div>
</template>

<script>
import {getIPs} from 'webrtc-ips';


export default {
 
  data() {
    return {
      userInfo: {
        username: "",
        password: "",
        userIP:""
      },

    };
  },
  methods: {
    async login() {
      if (!!this.userInfo.username && !!this.userInfo.password) {
        //console.log(this.userInfo);
        this.$store.dispatch("loadingHandler", true);
        await this.$store
          .dispatch("Login", this.userInfo)
          .then((msg) => {
            
            //console.log("UserRole",ur);
            var UserMode = process.env.VUE_APP_USER;
            // console.log(UserMode);
            // console.log(process.env.VUE_APP_COMNAME);
            if( UserMode=="DENY") {
              const ur = window.localStorage.getItem("userRole");
              if( ur=="admin") {
                if (msg == "success") {
                  this.$notify({
                    title: "成功",
                    message: "登入成功!",
                    type: "success",
                  });
                  this.$router.push("/Home");
                } else {
                  this.$notify({
                    title: "錯誤",
                    message: msg,
                    type: "warning",
                  });
                }
              }
              else
              {
                  this.$notify({
                    title: "錯誤",
                    message: "非管理者，無法登入",
                    type: "error",
                  });              
              }
            }
            else { // 使用者可以直接登入...
              if (msg == "success") {
                this.$notify({
                  title: "成功",
                  message: "登入成功!",
                  type: "success",
                });
                this.$router.push("/Home");
              } else {
                this.$notify({
                  title: "錯誤",
                  message: msg,
                  type: "warning",
                });
              }            

            }


            this.$store.dispatch("loadingHandler", false);
          })
          .catch((errMsg) => {
            this.$notify.error({
              title: "帳密錯誤或無此帳號",
              message: "帳密錯誤或無此帳號",
            });
            this.$store.dispatch("loadingHandler", false);
          });
      } else {
        this.$notify.error({
          title: "錯誤",
          message: "請確實輸入帳號或密碼！",
        });
      }
    },
    //取得試用者IP位置
    async getIP(){
      // You can pass in your custom stun server urls
      const userIP = await getIPs({ urls: "stun:stun.stunprotocol.org:3478" });
      this.userInfo.userIP = userIP[0].address;
      // => [{address: '95.108.174.12', v6: false}, {address: '2a02:6b8::408:5830:47a6:d045:a9ac', v6: true}]
    }
  },
  mounted(){
    this.getIP()
  }
};
</script>

<style lang="scss">
.login {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  // background: linear-gradient(-45deg, transparent 55%, #191972 0) right,
  //   linear-gradient(45deg, transparent 15px, #00abb9 0) left;

  background: linear-gradient(135deg,#191972 20%, #fff 50%, #00abb9 ) center center / 100% 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  &__header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &--title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      strong {
        font-size: 36px;
        letter-spacing: 5px;
        color: white;
      }
    }
  }

  &__func {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (min-width: 450px) {
      align-items: center;
    }

    &--infoCard {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      div {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        @media (min-width: 450px) {
          flex-direction: row;
        }

        label {
          min-width: 100px;
          max-width: 100px;
          font-size: 20px;
          color: white;
          letter-spacing: 10px;
        }

        input {
          width: 300px;
          border: none;
          padding: 8px;
          font-size: 16px;
          letter-spacing: 0.2rem;
        }
      }

      button {
        width: calc(100% - 20px);
        max-width: 400px;
        background: #191972;
        padding: 4px;
        border-radius: 8px;
        font-size: 20px;
        color: white;
        font-weight: bold;
        letter-spacing: 5px;
        transition: 0.6s;
        cursor: pointer;

        &:hover {
          background: #dfdff5;
          border: 3px solid #191972;
          color: #191972;
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    text-align: center;

    p {
      margin-bottom: 0;
      font-size: 18px;
      color: grey;
      letter-spacing: 2.5px;
    }
  }
}
</style>